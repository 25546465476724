import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
          const allRowsHead = [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fiscal_year_bn : search.fiscal_year, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_quarter_name'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.quaeter_name_bn : search.quaeter_name, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_type'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_type_bn : search.training_type, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_category'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_category_bn : search.training_category, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_title_bn : search.training_title, style: 'td', alignment: 'left' }
          ]
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['45%', '5%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.start_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.end_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.total_cader'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.total_non_cader'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.male_trainer'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.female_trainer'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.male_trainee'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.female_trainee'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.numbe_of_trainer'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.numbe_of_trainee'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        data.forEach((info, index) => {
            let caderCount
            if (info.cader_count > 0) {
              caderCount = vm.$n(info.cader_count, { useGrouping: false })
            } else {
                caderCount = '-'
            }
            let caderNonCount
            if (info.non_cader_count > 0) {
              caderNonCount = vm.$n(info.non_cader_count, { useGrouping: false })
            } else {
                caderNonCount = '-'
            }
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: info.circular_memo_no, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.training_title_bn : info.training_title, alignment: 'center', style: 'td' },
            { text: dateFormat(info.training_start_date), alignment: 'center', style: 'td' },
            { text: dateFormat(info.training_end_date), alignment: 'center', style: 'td' },
            { text: caderCount, alignment: 'center', style: 'td' },
            { text: caderNonCount, alignment: 'center', style: 'td' },
            { text: vm.$n(info.male_trainer, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: vm.$n(info.female_trainer, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: vm.$n(info.male_trainee, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: vm.$n(info.female_trainee, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: vm.$n(info.trainer_count, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: vm.$n(info.trainee_count, { useGrouping: false }), alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['5%', '8%', '12%', '9%', '9%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '7%'],
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 11,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 14,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 12,
              bold: true,
              alignment: 'center',
              margin: [0, -25, 0, 0]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
